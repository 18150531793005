<template>
    <v-container>
        <div class="" >
            <router-view></router-view>

        </div>
    </v-container>
</template>

<script>
    import {} from 'vuex';
    export default {
        name: "Admin",
        data() {
            return {
            };
        },
        created() {
            this.$root.showDrawerAdmin = true;
            console.log("Admin created");
        },
        destroyed(){
            this.$root.showDrawerAdmin=false;
        },
        computed: {
        },
    }
</script>

<style scoped>

</style>