<template>
    <v-container>
        <h2>Overview Admin</h2>
      <v-row >
        <v-card id="pending" style="background:#bfe9fc" class="margin-default padding" @click="goToChefs('PENDING')"><div style="display:inline;font-size: 55px;line-height: 55px;">{{pendings}}</div ><div style="display:inline;">Pendings</div> </v-card>
        <v-card id="pending" style="background:#bfe9fc" class="margin-default padding" @click="goToChefs('PENDING')"><div style="display:inline;font-size: 55px;line-height: 55px;">{{overpaids}}</div ><div style="display:inline;">Overpaid</div> </v-card>
        <v-card id="pending" style="background:#bfe9fc" class="margin-default padding" @click="goToChefs('PENDING')"><div style="display:inline;font-size: 55px;line-height: 55px;">{{underpaids}}</div ><div style="display:inline;">Underpaid</div> </v-card>
        <v-card id="pending" style="background:#bfe9fc" class="margin-default padding" @click="goToChefs('PENDING')"><div style="display:inline;font-size: 55px;line-height: 55px;">{{unpaids}}</div ><div style="display:inline;">Unpaid</div> </v-card>
<!--        <v-card id="confirmed" style="background:#b3efcb" class="margin-default padding" @click="goToBookings('CONFIRMED')"><div style="display:inline;font-size: 55px;line-height: 55px;">{{confirmations}}</div ><div style="display:inline;">Booking</div> </v-card>-->
<!--        <v-card id="cancelled" style="background:#fcc7c3" class="margin-default padding" @click="goToBookings('CANCELLED_CLIENT')"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Cancellation</div> </v-card>-->
<!--        <v-card  id="messages" style="background:#fff4b3" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->
        <!--          <v-card style="background:#2fd0bc" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Booking</div> </v-card>-->
        <!--          <v-card style="background:#f03776" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->
        <!--          <v-card style="background:#1ac7ba" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->
        <!--          <v-card style="background:#8b1cb1" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->

      </v-row>
    </v-container>
</template>

<script>
import {} from 'vuex';
// import Constants from '../../constants';
export default {
  name: "Overview",

  data() {
    return {
      pendings:0,
      overpaids:0,
      underpaids:0,
      unpaids:0,
      value:undefined,
      loading:false,
    };
  },
  mounted(){
      let that=this;
      this.$store.dispatch('getChefsAdminStatusPending').then(function(response){
        that.pendings = response.result;
        that.loading = false;
        }
      );
    this.$store.dispatch('getBookingsAdminPaymentStatus', "PAID").then(function(response){
          that.overpaids = response.result;
          that.loading = false;
        }
    );
    this.$store.dispatch('getBookingsAdminPaymentStatus',"PARTIALLY").then(function(response){
          that.underpaids = response.result;
          that.loading = false;
        }
    );
    this.$store.dispatch('getBookingsAdminPaymentStatus',"UNPAID").then(function(response){
          that.unpaids = response.result;
          that.loading = false;
        }
    );
  },
  methods: {
    goToChefs(status){
      this.$root.chefStatus=this.$store.state.statusProfile.find(s => s.name===status);
      this.$router.push({path: `chefs`})
    },

  },
  computed: {
  },

}
</script>

<style scoped>

</style>