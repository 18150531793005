<template>
    <v-container v-if="state">
        <h2>Parameters</h2>
        <v-row  v-for="(parameter,index) in $store.state.parameter" :key="index">
            <v-col>
                <v-text-field
                              :label="parameter.displayName"
                              type="number"
                              :suffix="getSuffix(parameter)"
                              :value="findParameter(parameter).value"
                              @change="setParameter($event,parameter)"
                >
                </v-text-field>
            </v-col>

        </v-row>
      <v-btn outlined @click="saveParameter(parameters)" color="primary" class="margin-right-default" :loading="loading">Save</v-btn>
    </v-container>
</template>

<script>
    import {} from 'vuex';
    import Constants from '../../constants';
    export default {
        name: "Bank",
        data() {
            return {
                parameters:undefined,
                commission:undefined,
                rejectDelay:undefined,
                userLocal:undefined,
                loading:false,
            };
        },
        mounted(){
            this.state
        },
        methods: {
          getSuffix(parameter){
            if (parameter.name==="COMMISSION"){
              return "%"
            }else if (parameter.name==='NO_CANCELLATION_PERIOD' || parameter.name==='PAYOUT_DELAY' || parameter.name==='CHECK_PAYOUT_DELAY' || parameter.name==='REMINDER_DAYS_CHEF' || parameter.name==='REMINDER_DAYS_CLIENT'){
              return "D";
            }else {
              return "H";
            }
          },
          setParameter(value,parameter){
            if (this.parameters!==undefined){
              let param= this.parameters.find(p=>p.name.name===parameter.name);
              param.value=value;
              if (param!==undefined){
                return param;
              }else{
                let newParam ={name:parameter,value:value};
                this.parameters.push(newParam);
                return newParam;
              }
            }
          },
          findParameter(parameter){
            if (this.parameters!==undefined){
              let param= this.parameters.find(p=>p.name.name===parameter.name);
              if (param!==undefined){
                return param;
              }else{
                let newParam ={name:parameter,value:undefined};
                this.parameters.push(newParam);
                return newParam;
              }
            }
          },
            initParameters(parameters){
                let copy=JSON.parse(JSON.stringify(parameters));

                this.parameters=copy;
            },
            saveParameter() {
                this.loading=true;
                this.$store.dispatch('saveParameters', this.parameters)
                    .then((response) => {
                        if (response !== undefined && response.statusCode === 200) {
                            this.$store.dispatch('showSnackbar', {
                                text: Constants.SAVED,
                                color: "primary"
                            })
                        }
                    }).finally(()=>{this.loading=false;});
            },
        },
        computed: {
            state() {
              let parameters=this.$store.state.parameters
              console.log(parameters)
              if (parameters!=undefined){
                  this.initParameters(parameters);
              }
              return parameters;
            }
        },
        beforeRouteLeave (to, from, next) {
            console.log("route leave");
            if (this.parameters!==undefined){
              let copyLocal=JSON.parse(JSON.stringify(this.parameters));
              let local=JSON.stringify(copyLocal);
              let remote=JSON.stringify(this.$store.state.parameters);
              if (local !== remote){
                this.$parent.dirty=true;
                this.$parent.next=next;
              }else{
                next();
              }
            }else{
              next();
            }

        }
    }
</script>

<style scoped>

</style>