<template>
    <v-container>
        <h2>Chefs</h2>
      <v-row class="no-margin-top-bottom" style="align-items: baseline">
        <v-col>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
              @keydown.enter="searchChefs"
              class="no-margin-top-bottom"
              dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
              id="status"
                    v-if="$store.state.statusProfile"
                    v-model="status"
                    :items="$store.state.statusProfile"
                    item-text="displayName"
                    :return-object=true
                    label="Status"
                    class="no-margin-top-bottom"
                    dense
                    clearable
          ></v-select>
        </v-col>
        <v-btn align="center" outlined @click="searchChefs" class="margin-left-right-default">Search</v-btn>
      </v-row>

        <v-data-table :loading="loading"
                      :headers="headers"
                      :items="chefs"
                      :multi-sort="true"
                      class="elevation-1 pointer"
                      :hide-default-footer=false
                            :expanded="expanded"
                      item-key="id"
                      :show-expand="false"
                      :server-items-length="totalItems"
                      :options.sync="options"
                      :footer-props="{'items-per-page-options': [ 20, 30, 40, 50]}"
                      @click:row="openChef"
        >
<!--          @click:row="openChef"-->
            <template v-slot:item.actions="{ item }" style="width: 1px; min-width: 1px;">
                <v-col style="padding:0px" >
                    <v-btn
                            icon
                    >
                        <v-icon
                                small
                                @click.stop="openChef(item)"
                        >
                            mdi-pencil
                        </v-icon>
                    </v-btn>

                    <v-btn
                            :loading="item.loading"
                            :disabled="item.loading"
                            icon
                    >
                        <v-icon
                                small
                                @click.stop="deleteUser(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </v-col>
            </template>
<!--            <template v-slot:expanded-item="{ headers, item }" >-->
<!--                <td style="white-space: pre-wrap" class=" v-data-table__mobile-row caption">{{ item.details }}</td>-->
<!--                <td :colspan="headers.length-1" style="white-space: pre-wrap" class="v-data-table__mobile-row caption">{{item.details2}}</td>-->
<!--            </template>-->
        </v-data-table>
      <div class="text--secondary">Click on a row to see the chef</div>
    </v-container>
</template>

<script>
    import Constants from '../../constants';
    export default {
        name: "Chefs",
        data() {
            let headers= [
                {
                    text: 'Status',
                    align: 'start',
                    sortable: false,
                    value: 'profile.status.displayName',
                },
                { text: 'firtname', value: 'userDetails.firstname', sortable: true },
                { text: 'lastname', value: 'userDetails.lastname', sortable: true },
                { text: 'email', value: 'email', sortable: true },
                { text: 'profile', value: 'profile.profileName', sortable: true },
                { text: 'Actions', value: 'actions', sortable: false },
                // { text: '', value: 'data-table-expand'},
            ]
            return {
              status:undefined,
              updateSize:20,
              updatePage:undefined,
              options: {sortBy:["userDetails.firstname"],sortDesc:["false"]},
              expanded:[],
              headers,
              search:undefined,
              loading:false
            };
        },
        created() {
          if (this.$root.chefStatus!=undefined){
            this.status=this.$root.chefStatus;
            this.$root.chefStatus=undefined;
          }
            // if (this.$store.state.chefsAdmin===undefined){
            //   this.loading = true;
            //   let that=this;
            //     this.$store.dispatch('getChefsAdmin').then(function(){
            //       that.loading = false;
            //         }
            //     );
            // }
        },
        mounted(){
        },
        destroyed(){
        },
        computed: {
          chefs() {
            if (this.$store.state.chefsAdmin!==undefined){
              this.log("chefs updated");
              return this.$store.state.chefsAdmin.content;
            }else{
              return undefined;
            }
          },
          totalItems(){
            if (this.$store.state.chefsAdmin!==undefined){
              return this.$store.state.chefsAdmin.totalElements;
            }else{
              return undefined
            }
          },
          size(){
            if (this.$store.state.chefsAdmin!==undefined){
              return this.$store.state.chefsAdmin.size;
            }else{
              return undefined
            }
          },
        },
        methods:{
          openChef(user){
            this.$router.push({path: '/'+user.id+`/dashboard/overview`})
          },
          deleteUser(user){
              var r = confirm("Are you sure you want to delete this user ("+user.email+")!");
              if (r == true) {
                this.log("ok");
                this.$set(user,'loading', true);
                this.$store.dispatch('deleteUser',user).then(response => {
                  if (response!=undefined){
                    this.$store.dispatch('showSnackbar', {
                      text: Constants.DELETED,
                      color: "primary"
                    })
                    this.log(response);
                    this.$store.dispatch('getChefsAdmin');
                  }
                }).finally(()=>{
                  this.$set(user,'loading', undefined);
                });
              } else {
                  this.log("cancel");
              }
          },
          searchChefs(){
            this.loading=true;
            let status=this.status!=undefined?this.status.name:"";
            this.$store.dispatch('getChefsAdmin',{pageNumber:0,size:this.updateSize,sortBy:this.options.sortBy.join(),sortDesc:this.options.sortDesc.join(),search:this.search,status:status}).finally(()=> {
                  this.loading = false;
                }
            );
          },
        },
      watch:{
        options(val){
          this.log(val);
          let status=this.status!=undefined?this.status.name:"";
          this.loading = true;
          this.$store.dispatch('getChefsAdmin',{pageNumber:val.page-1,size:val.itemsPerPage,sortBy:val.sortBy.join(),sortDesc:val.sortDesc.join(),search:this.search,status:status}).finally(()=> {
                this.loading = false;
              }
          );
        },
      }
    }
</script>

<style scoped>

</style>