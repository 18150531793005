<template>
    <v-dialog v-model="bookingItemsDialog" persistent max-width="1200px">
        <v-form
                ref="formBookingItems"
                v-model="valid"
                lazy-validation>
            <v-card>
                <v-card-title>
                    <div class="headline">Booking Items</div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                      <v-data-table v-if="filteredItems"
                                    :multi-sort="true"
                                    :loading="loading"
                                    :headers="headers"
                                    :items="filteredItems"
                                    :hide-default-footer=true
                                    class="elevation-1"
                                    :expanded="expanded"
                                    item-key="id"
                                    :show-expand="false"
                                    :item-class="getClass"
                      >
                        <template v-slot:top>
                          <v-toolbar
                              flat
                          >
                            <v-toolbar-title>Items</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-switch
                                hide-details
                                class="caption no-margin-padding"
                                v-model="showCancelledItems"
                                label="Show cancelled items"
                            ></v-switch>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog
                                v-model="dialog"
                                max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                  Add Item
                                </v-btn>
                              </template>
                              <v-card>
                                <v-form
                                    ref="formItem"
                                    v-model="validItem"
                                    lazy-validation
                                >
                                <v-card-title>
                                  <span class="headline">Item</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                          cols="12"
                                          sm="6"
                                          md="6"
                                      >
                                        <v-text-field
                                            id="item_description"
                                            v-model="editedItem.description"
                                            label="Description"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col
                                          cols="12"
                                          sm="6"
                                          md="6"
                                      >
                                        <v-select
                                            id="item_type"
                                            v-model="editedItem.itemType"
                                            :items="itemTypes()"
                                            item-text="displayName"
                                            item-value="name"
                                            attach
                                            label="Type"
                                            color="#00695C"
                                            :rules="[v => !!v && v.length !== 0 || 'You must select a type!']"
                                            required
                                            return-object
                                        ></v-select>
                                      </v-col>
                                      <v-col
                                          cols="12"
                                          sm="6"
                                          md="4"
                                      >
                                        <v-text-field
                                            id="unit_price"
                                            v-model="editedItem.unitPrice"
                                            label="Unit Price"
                                            v-on:input="updatePriceWithComm"
                                            type="number"
                                            :rules="[v => v!==undefined || 'Unit Price is required']"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col
                                          cols="12"
                                          sm="6"
                                          md="4"
                                      >
                                        <v-text-field
                                            id="retail_price"
                                            v-model="editedItem.unitPriceWithComm"
                                            label="Retail Price"
                                            v-on:input="updatePrice"
                                            type="number"
                                            :rules="[v => v!==undefined || 'Retail Price is required']"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col
                                          cols="12"
                                          sm="6"
                                          md="4"
                                      >
                                        <v-text-field
                                            id="qty"
                                            v-model.number="editedItem.qty"
                                            label="Qty"
                                            type="number"
                                            :min="1"
                                            aria-valuemin="1"
                                            :rules="[v => !!v || 'Qty is required']"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col
                                          cols="12"
                                          sm="12"
                                          md="12"
                                      >
                                        <v-textarea
                                            id="comment"
                                            v-model="editedItem.comment"
                                            label="Comment"
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn id="btn_close_item" outlined @click="closeItem">Cancel</v-btn>
                                  <v-btn id="btn_save_item" outlined color="primary"
                                         @click="validateItem()!=true?null:saveItem()" :disabled="!validItem" :loading="loadingModify">Save</v-btn>
                                </v-card-actions>
                                </v-form>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDeleteItem" max-width="500px">
                              <v-card>
                                <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn id="btn_close_delete_item" outlined text @click="closeDeleteItem">Cancel</v-btn>
                                  <v-btn id="btn_confirm_delete_item" outlined color="primary" @click="deleteItemConfirm">OK</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ item.unitPrice !== undefined && item.unitPrice !== null ?roundToXDigits(item.unitPrice,2):"" }}
                        </template>
                        <template v-slot:item.unitPriceWithComm="{ item }">
                          {{ item.unitPriceWithComm !== undefined && item.unitPriceWithComm !== null ?roundToXDigits(item.unitPriceWithComm,2):"" }}
                        </template>
                        <template v-slot:expanded-item="{ headers, item }" >
                          <td :colspan="headers.length" style="white-space: pre-wrap" class="  caption">{{ item.comment }}</td>
                        </template>
                        <template v-if="isAdmin" v-slot:item.actions="{ item }" style="width: 1px; min-width: 1px;">
                          <v-col style="padding:0px" >
                            <v-btn id="btn_delete" icon>
                              <v-icon small @click.capture="editItem(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                            <v-btn id="btn_cancelled" icon al v-if="!item.cancelled">
                              <v-icon small @click.capture="deleteItem(item)">
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </template>
                        <template v-slot:body.append="{ }" >
                          <tr>
                            <td :colspan="2" style="white-space: pre-wrap" class="class"><b>Total</b></td>
                            <td style="white-space: pre-wrap" class="class text-end"><b>{{getTotal.total}}</b></td>
                            <td style="white-space: pre-wrap" class="class text-end"><b>{{getTotal.totalRetail}}</b></td>
                            <td :colspan="3" class="class"></td>
                          </tr>
                        </template>
                      </v-data-table>
                      <div v-if="waiterRemove">
                        A waiter is not needed anymore
                      </div>
                      <div v-if="waiterNeeded">
                        A waiter is needed with this booking (Unit Price:${{waiterPrice}}, Retail Price:${{this.getPriceWithCommission2(waiterPrice,this.booking.commissionPct)}})
                      </div>
                      <div v-if="minPriceRemove">
                        Minimum order remaining is not needed anymore
                      </div>
                      <div v-if="minPriceSuggestion">
                        A minimum order is needed with this booking (Unit Price: ${{this.getPriceWithCommission2(minPriceSuggestion,this.booking.commissionPct)}}, Retail Price :${{minPriceSuggestion}})
                      </div>
<!--TRANSACTION-->
                      <v-data-table v-if="isAdmin"
                                    :multi-sort="true"
                                    :loading="loading"
                                    :headers="headersTransaction"
                                    :items="filteredTransactions"
                                    :hide-default-footer=true
                                    class="elevation-1 margin-top-default"
                                    :expanded="expandedTransaction"
                                    item-key="id"
                                    :show-expand="false"
                                    :item-class="getClass"
                      >
                        <template v-slot:top>
                          <v-toolbar
                              flat
                          >
                            <v-toolbar-title>Transactions</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-switch
                                id="swt_cancelled_transaction"
                                hide-details
                                class="caption no-margin-padding"
                                v-model="showCancelledTransactions"
                                label="Show cancelled transactions"
                            ></v-switch>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog
                                v-model="dialogTransactions"
                                max-width="500px"
                            >
                              <template v-if="isAdmin"  v-slot:activator="{ on, attrs }">
                                <v-btn
                                    id="btn_add_transaction"
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                  Add Transaction
                                </v-btn>
                              </template>
                              <v-card>
                                <v-form
                                    ref="formTransaction"
                                    v-model="validTransaction"
                                    lazy-validation>
                                  <v-card-title>
                                    <span class="headline">Transaction</span>
                                  </v-card-title>

                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                        >
                                          <v-text-field
                                              id="transaction_description"
                                              v-model="editedTransaction.description"
                                              label="Description"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                        >
                                          <v-select
                                              id="transaction_type"
                                              v-model="editedTransaction.transactionType"
                                              :items="transactionTypes()"
                                              item-text="displayName"
                                              item-value="name"
                                              attach
                                              label="Type"
                                              color="#00695C"
                                              :rules="[v => !!v && v.length !== 0 || 'You must select a type!']"
                                              required
                                              return-object
                                          ></v-select>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                          <v-text-field
                                              id="transaction_amount"
                                              v-model="editedTransaction.amount"
                                              label="Amount"
                                              type="number"
                                              :rules="[v => v!==undefined || 'Amount is required']"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="12"
                                        >
                                          <v-textarea
                                              id="transaction_comment"
                                              v-model="editedTransaction.comment"
                                              label="Comment"
                                          ></v-textarea>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn outlined @click="closeTransaction">Cancel</v-btn>
                                    <v-btn outlined color="primary"
                                           @click="validateTransaction()!=true?null:saveTransaction()" :disabled="!validTransaction" :loading="loadingModify">Save</v-btn>
                                  </v-card-actions>
                                </v-form>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDeleteTransaction" max-width="500px">
                              <v-card>
                                <v-card-title class="headline">Are you sure you want to delete this transaction?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn id="transaction_close_delete" outlined text @click="closeDeleteTransaction">Cancel</v-btn>
                                  <v-btn id="transaction_confirm_delete" outlined color="primary" @click="deleteTransactionConfirm">OK</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }" >
                          <td :colspan="headers.length" style="white-space: pre-wrap" class="  caption">{{ item.comment }}</td>
                        </template>
                        <template v-if="isAdmin" v-slot:item.actions="{ item }" style="width: 1px; min-width: 1px;">
                          <v-col style="padding:0px" >
                            <v-btn icon>
                              <v-icon small @click.capture="editTransaction(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                            <v-btn id="btn_transaction_delete" icon al v-if="!item.cancelled">
                              <v-icon small @click.capture="deleteTransaction(item)">
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </template>
                        <template v-slot:body.append="{ }" >
                          <tr>
                            <td :colspan="1" style="white-space: pre-wrap" class="class"><b>Total</b></td>
                            <td  :colspan="2" style="white-space: pre-wrap" class="class text-end"><b>{{getBalance.balance}}</b></td>
                            <td :colspan="3" class="class"></td>
                          </tr>
                        </template>
                      </v-data-table>
<!--HISTORY                      -->
                      <v-data-table v-if="isAdmin && bookingHistory!=undefined"
                                    :multi-sort="true"
                                    :loading="loading"
                                    :headers="headersHistory"
                                    :items="bookingHistory"
                                    :hide-default-footer=false
                                    class="elevation-1 margin-top-default"
                                    :expanded="expandedHistory"
                                    item-key="id"
                                    :show-expand="false"
                                    :item-class="getClass"
                      >
                        <template v-slot:top>
                          <v-toolbar
                              flat
                          >
                            <v-toolbar-title>History</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }" >
                          <td :colspan="headers.length" style="white-space: pre-wrap" class="  caption">{{ item.comment }}</td>
                        </template>
                        <template v-slot:item.creationDate="{ headers, item }" >
                          <td style="white-space: pre-wrap" class="  caption">{{ new Date(item.creationDate) }}</td>
                        </template>

<!--                        <template v-slot:item.actions="{ item }" style="width: 1px; min-width: 1px;">-->
<!--                          <v-col style="padding:0px" >-->
<!--                            <v-btn icon>-->
<!--                              <v-icon small @click.capture="editTransaction(item)">-->
<!--                                mdi-pencil-->
<!--                              </v-icon>-->
<!--                            </v-btn>-->
<!--                            <v-btn icon al v-if="!item.cancelled">-->
<!--                              <v-icon small @click.capture="deleteTransaction(item)">-->
<!--                                mdi-delete-->
<!--                              </v-icon>-->
<!--                            </v-btn>-->
<!--                          </v-col>-->
<!--                        </template>-->
                      </v-data-table>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn btn="btn_close" outlined @click="closeAction()">Close</v-btn>
                  <v-btn btn="btn_save" outlined color="primary" @click="editItemsTransactions()" :disabled="!valid" :loading="loadingModify">save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import Constants from "@/constants";

export default {
  name: "PopupBookingItems",
  props: {
    booking:Object,
    bookingItemsDialog:Boolean,
    closeAction: Function,
    editAction:Function,
  },
  data() {
    let headers= [
      { text: 'Description', value: 'description', sortable: false },
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'itemType.displayName',
      },
      { text: 'Unit Price', value: 'unitPrice', sortable: true, align: 'end',},
      { text: 'Retail Price', value: 'unitPriceWithComm', sortable: true,align: 'end' },
      { text: 'Qty', value: 'qty', sortable: false,align: 'end', },
      { text: 'Actions', value: 'actions', sortable: false },
      { text: '', value: 'data-table-expand', sortable: false},
    ];
    let headersTransaction= [
      // { text: 'Description', value: 'description', sortable: false },
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'transactionType.displayName',
      },
      { text: 'Status', align: 'start', sortable: true, value: 'status.displayName'},
      { text: 'Amount', value: 'amount', sortable: true,align: 'end' },
      { text: 'Fee', value: 'fee', sortable: true ,align: 'end'},
      { text: 'Tax', value: 'tax', sortable: true ,align: 'end'},
      { text: 'Actions', value: 'actions', sortable: false ,align: 'end'},
      { text: '', value: 'data-table-expand', sortable: false},
    ];
    let headersHistory= [
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status.displayName',
      },
      { text: 'Date', value: 'creationDate', sortable: true },
      // { text: 'Actions', value: 'actions', sortable: false },
      // { text: '', value: 'data-table-expand'},
    ];
    return {
      items:this.booking.items,
      transactions:this.booking.transactions,
      bookingHistory:this.booking.history,
      errorEdit:undefined,
      waiterPrice:undefined,
      waiterRemove:undefined,
      waiterNeeded:undefined,
      minPriceSuggestion:undefined,
      minPriceRemove:undefined,
      dialog: false,
      dialogTransactions: false,
      dialogDeleteItem: false,
      dialogDeleteTransaction: false,
      headers,
      headersTransaction,
      headersHistory,
      valid:true,
      validItem:true,
      validTransaction:true,
      showCancelledItems:false,
      showCancelledTransactions:false,
      loading:false,
      loadingModify:false,
      expanded:[],
      expandedTransaction:[],
      expandedHistory:[],
      editedItemIndex: -1,
      editedItem: {
        description: '',
        itemType: undefined,
        unitPrice: undefined,
        unitPriceWithComm: undefined,
        qty: 1,
        cancelled:false
      },
      editedTransaction: {
        description: '',
        transactionType: undefined,
        amount: undefined,
        currency: 'AUD',
        cancelled:false
      },
      editedTransactionIndex: -1,
      defaultItem: {
        description: '',
        itemType: undefined,
        unitPrice: undefined,
        unitPriceWithComm: undefined,
        qty: 1,
        cancelled:false
      },
      defaultTransaction: {
        description: '',
        transactionType: undefined,
        amount: undefined,
        currency: 'AUD',
        cancelled:false
      },
    };
  },
  mounted(){
    console.log("mounted");
  },
  created(){
    console.log("created");
  },
  methods: {
    getClass(item){
      return item.cancelled?"line-through":"";
    },
    updatePrice(p){
      if (p!==undefined && !p.isNaN){
        // this.editedItem.unitPrice=Math.round(p/(1+this.booking.commissionPct/100)*10/5)*5/10;
        this.editedItem.unitPrice=this.getPriceWithoutCommission2(p,this.booking.commissionPct);
      }
    },
    updatePriceWithComm(p){
      if (p!=undefined && !p.isNaN){
        this.editedItem.unitPriceWithComm=this.getPriceWithCommission2(p,this.booking.commissionPct);
      }
    },
    duplicate(item) {
      console.log(item);
    },
    validateItem() {
      if (this.$refs.formItem !== undefined) {
        return this.$refs.formItem.validate();
      }
      return true;
    },
    validateTransaction() {
      if (this.$refs.formTransaction !== undefined) {
        return this.$refs.formTransaction.validate();
      }
      return true;
    },
    editItem (item) {
      let copy=JSON.parse(JSON.stringify(item));
      this.editedItemIndex = this.booking.items.indexOf(item)
      this.editedItem = Object.assign({}, copy)
      this.dialog = true
    },
    editTransaction (item) {
      let copy=JSON.parse(JSON.stringify(item));
      this.editedTransactionIndex = this.booking.transactions.indexOf(item)
      this.editedTransaction = Object.assign({}, copy)
      this.dialogTransactions = true
    },
    deleteItem(item) {
      this.editedItemIndex = this.booking.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDeleteItem = true
    },
    deleteTransaction(item) {
      this.editedTransactionIndex = this.booking.transactions.indexOf(item)
      this.editedTransaction = Object.assign({}, item)
      this.dialogDeleteTransaction = true
    },
    deleteItemConfirm() {
      this.booking.items[this.editedItemIndex].cancelled=true;
      this.closeDeleteItem()
    },
    deleteTransactionConfirm() {
      this.booking.transactions[this.editedTransactionIndex].cancelled=true;
      this.closeDeleteTransaction()
    },
    closeItem() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    closeTransaction() {
      this.dialogTransactions = false
      this.$nextTick(() => {
        this.editedTransaction = Object.assign({}, this.defaultTransaction)
        this.editedTransactionIndex = -1
      })
    },
    closeDeleteItem() {
      this.dialogDeleteItem = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    closeDeleteTransaction() {
      this.dialogDeleteTransaction = false
      this.$nextTick(() => {
        this.editedTransaction = Object.assign({}, this.defaultTransaction)
        this.editedTransactionIndex = -1
      })
    },
    saveItem() {
      if (this.editedItemIndex > -1) {
        this.booking.items[this.editedItemIndex].cancelled=true;
        this.editedItem.id=undefined;
      }
      this.items.push(this.editedItem)
      this.closeItem()
    },
    saveTransaction() {
      if (this.transactions===undefined){
        this.booking.transactions= [];
        this.transactions=this.booking.transactions;
      }
      if (this.editedTransactionIndex > -1) {
        this.booking.transactions[this.editedTransactionIndex].cancelled=true;
        this.editedTransaction.id=undefined;
      }
      this.transactions.push(this.editedTransaction)
      this.closeTransaction()
    },
    async editItemsTransactions() {
      // update the booking
      this.loadingModify=true;
      // remove location

      let booking={};
      booking.reference=this.booking.reference;
      booking.items=this.booking.items;
      booking.transactions=this.booking.transactions;
      this.$store.dispatch('modifyBooking', booking )
          .then((response) => {
            if (response !== undefined && response.statusCode === 200) {
              this.$store.dispatch('showSnackbar', {
                text: Constants.SAVED,
                color: "primary"
              })
              this.editAction(this.booking);
            }
          }).finally(()=>{this.loadingModify=false;});
    },
    checkItems(){
      let total=0;
      // let totalWoComm=0;
      let totalDishes=0;
      let waiterFound=false;
      let remainingFound=0;
      this.minPriceSuggestion=undefined;
      this.items.forEach(item => {
          if (!item.cancelled){
            if (item.itemType.name === "DISH") {
              total += item.unitPriceWithComm * item.qty;
              // totalWoComm += item.unitPrice * item.qty;
              totalDishes += item.qty;
            }
            if (item.itemType.name === "WAITER") {
              waiterFound = true;
            }
            if (item.itemType.name === "MINIMUM_REMAINING") {
              remainingFound = this.roundToXDigits(item.unitPriceWithComm,2);
            }
          }
        }
      );

      if (this.booking.serviceSerialized.minWaiterDishes!==undefined && totalDishes>=this.booking.serviceSerialized.minWaiterDishes){
        this.waiterNeeded=true;
        this.waiterPrice= this.booking.serviceSerialized.waiterPrice;
      }else{
        this.waiterPrice=undefined;
        this.waiterNeeded=undefined;
      }

      if (waiterFound && this.waiterNeeded===undefined){
        this.waiterRemove=true;
      }

      let remaining;
      if (this.booking.serviceSerialized.minPrice!==undefined && total<this.getPriceWithCommission2(this.booking.serviceSerialized.minPrice,this.booking.commissionPct)){
        remaining = (this.getPriceWithCommission2(this.booking.serviceSerialized.minPrice,this.booking.commissionPct)-total);
      }else{
        remaining=undefined;
      }

      if (remainingFound && remaining===undefined){
        this.minPriceRemove=true;
      }
      if (remainingFound>0 && remaining!==remainingFound){
        this.minPriceSuggestion=remaining;
      }

      return this.roundToXDigits(total,2);
    },
    itemTypes(){
      let result=this.$store.state.bookingItemType;
      if (this.booking.items.filter(i=>!i.cancelled && i.itemType.name==="MINIMUM_REMAINING").length>0){
        result=result.filter(t=>t.name!=="MINIMUM_REMAINING");
      }
      if (!this.isAdmin){
        result=result.filter(t=>t.name!=="PAYMENT" && t.name!=="REFUND");
      }
      return result
    },
    transactionTypes(){
      let result=this.$store.state.bookingTransactionType;
      return result
    },
    // bookingHistory(){
    //   let result;
    //   if (this.history!==undefined){
    //     console.log(this.history);
    //     result=this.history;
    //   }
    //   return result;
    // },
  },
  computed:{
    filteredItems(){
      let result;
      if (this.items.length>0 && this.showCancelledItems){
        result= this.items.slice(0).sort(function(a, b){return a.itemType.order-b.itemType.order});
      }else{
        result= this.items.filter(i=> !i.cancelled).sort(function(a, b){return a.itemType.order-b.itemType.order});
      }
      result.sort(function(a,b){
        if (a.itemType.name==="DISH" && b.itemType.name==="DISH" && a.item.dishType!==undefined && b.item.dishType !==undefined ) {
          return a.item.dishType.order-b.item.dishType.order
        }else{
          return 0
        }});
      this.checkItems();
      return result;
    },
    filteredTransactions(){
      let result;
      if (this.transactions!==undefined){
        console.log(this.transactions.length)
        if (this.transactions.length>0 && this.showCancelledTransactions){
          result= this.transactions.slice(0).sort(function(a, b){return a.transactionType.order-b.transactionType.order});
        }else{
          result= this.transactions.filter(i=> !i.cancelled).sort(function(a, b){
            if (a.transactionType!==undefined && a.transactionType!== null && a.transactionType.order !== undefined && b.transactionType!==undefined && b.transactionType!==null && b.transactionType.order !== undefined ){
              return a.transactionType.order-b.transactionType.order
            }else{
              return -1
            }
          });
        }
      }
      return result;
    },
    getTotal(){
      let result={total:0,totalRetail:0}
      this.items.filter(i=> !i.cancelled ).forEach(i=>{
        result.total+=i.unitPrice*i.qty;
        result.totalRetail+=i.unitPriceWithComm*i.qty;
      });
      result.total=this.roundToXDigits(result.total,2);
      result.totalRetail=this.roundToXDigits(result.totalRetail,2);
      return result;
    },
    getBalance(){
      let result={balance:0}
      if (this.transactions!==undefined){
        this.transactions.filter(i=> !i.cancelled ).forEach(i=>{
          if (i.transactionType.name==="REFUND" || i.transactionType.name==="PAYOUT" ){
            result.balance-=parseFloat(i.amount);
          }else{
            result.balance+=parseFloat(i.amount);
          }
        });
      }
      result.balance=this.roundToXDigits(result.balance,2);
      return result;
    },
  },
  watch: {
    dialog (val) {
      val || this.closeItem()
    },
    dialogDelete (val) {
      val || this.closeDeleteItem()
    },
  },
  destroyed(){
    console.log("destroyed");
  },
}
</script>

<style scoped>

</style>