<template>
    <v-container>
        <h2>Potential Customers</h2>
        <v-row no-gutters style="align-items: baseline">
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by email or address"
              single-line
              hide-details
              @keydown.enter="searchPotentialCustomers"
          ></v-text-field>
          <v-btn align="center" outlined @click="searchPotentialCustomers" class="margin-left-right-default">Search</v-btn>
        </v-row>
        <v-data-table
                :headers="headers"
                :loading="loading"
                :items="potentialCustomers"
                class="elevation-1"
                :hide-default-footer=false
                :server-items-length="totalItems"
                :options.sync="options"
                :footer-props="{'items-per-page-options': [1,2,3,10, 20, 30, 40, 50]}"
        >
          <template v-slot:item.creationDate="{ item }">
            {{ formatDate(item.creationDate) }}
          </template>
        </v-data-table>
    </v-container>
</template>

<script>
  import moment from 'moment';
    export default {
        name: "PotentialCustomers",
        data() {
            return {
                options: {sortBy:["creationDate"],sortDesc:["false"],itemsPerPage:20},
                headers: [
                    { text: 'Email', value: 'email', sortable: true },
                    { text: 'Guests', value: 'nbGuests', sortable: true },
                    { text: 'Date', value: 'date', sortable: true },
                    { text: 'Time', value: 'time', sortable: true },
                    { text: 'Address', value: 'address.fullAddress', sortable: true },
                    { text: 'Created', value: 'creationDate', sortable: true },
                ],
                search:undefined,
                loading:false
            };
        },
        created() {
            if (this.$store.state.potentialCustomers===undefined){
                this.loading = true;
                let that=this;
                this.$store.dispatch('getPotentialCustomers',{pageNumber:0,size:1,sortBy:'',sortDesc:''}).then(function(){
                    that.loading = false;
                }
                );
            }
        },
      computed: {
        potentialCustomers() {
          if (this.$store.state.potentialCustomers!==undefined){
            console.log("potentialCustomers updated");
            return this.$store.state.potentialCustomers.content;
          }else{
            return []; // Return an empty array if undefined
          }
        },
        totalItems(){
          if (this.$store.state.potentialCustomers!==undefined){
            return this.$store.state.potentialCustomers.totalElements;
          }else{
            return 0; // Return 0 if undefined
          }
        },
      },
      methods:{
        searchPotentialCustomers(){
          this.loading=true;
          this.$store.dispatch('getPotentialCustomers',{pageNumber:0,size:this.options.itemsPerPage,sortBy:this.options.sortBy.join(),sortDesc:this.options.sortDesc.join(),search:this.search}).finally(()=> {
                this.loading = false;
              }
          );
        },
        formatDate(date) {
          return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      watch: {
        options(val) {
          this.loading=true;
          let params = {
            pageNumber: val.page-1,
            size: val.itemsPerPage
          };
          if (val.sortBy.length > 0) {
            params.sortBy = val.sortBy.join();
            params.sortDesc = val.sortDesc.join();
          }
          this.$store.dispatch('getPotentialCustomers', params).finally(()=> {
                this.loading = false;
              }
          );
        },
      }
    }
</script>

<style scoped>

</style>
