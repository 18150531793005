<template>
    <v-container>
        <h2>Clients</h2>
      <v-row no-gutters style="align-items: baseline">
        <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search by email, firstname, lastname, phone or address"
                single-line
                hide-details
                @keydown.enter="searchClients"
        ></v-text-field>
        <v-btn align="center" outlined @click="searchClients" class="margin-left-right-default">Search</v-btn>
      </v-row>
<!--      :items-per-page.sync="updateSize"-->
<!--      :page.sync="updatePage"-->
        <v-data-table
                      :headers="headers"
                      :loading="loading"
                      :multi-sort="true"
                      :items="clients"
                      class="elevation-1"
                      :hide-default-footer=false
                      :expanded="expanded"
                      item-key="id"
                      show-expand
                      :server-items-length="totalItems"
                      :options.sync="options"
                      :footer-props="{'items-per-page-options': [1,2,3,10, 20, 30, 40, 50]}"
        >
            <template v-slot:item.actions="{ item }" style="width: 1px; min-width: 1px;">
                <v-col style="padding:0px" >
                    <v-btn
                            icon
                    >
                        <v-icon
                                small
                                @click="$router.push({path: '/'+item.id+`/account/overview`})"
                        >
                            mdi-pencil
                        </v-icon>
                    </v-btn>

                    <v-btn
                            :loading="item.loading"
                            :disabled="item.loading"
                            icon
                    >
                        <v-icon
                                small
                                @click="deleteUser(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </v-col>
            </template>
            <template v-slot:expanded-item="{ headers, item }" >
                <td style="white-space: pre-wrap" class=" v-data-table__mobile-row caption">{{ item.details }}</td>
                <td :colspan="headers.length-1" style="white-space: pre-wrap" class="v-data-table__mobile-row caption">{{item.details2}}</td>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import Constants from '../../constants';
    export default {
      name: "Clients",
      data() {
          let headers= [
              { text: 'firtname', value: 'userDetails.firstname', sortable: true },
              { text: 'lastname', value: 'userDetails.lastname', sortable: true },
              { text: 'email', value: 'email', sortable: true },
              { text: 'Actions', value: 'actions', sortable: false },
              { text: '', value: 'data-table-expand'},
          ]
          return {
            updatePage:undefined,
            options: {sortBy:["userDetails.firstname"],sortDesc:["false"],itemsPerPage:20},
            expanded:[],
            headers,
            search:undefined,
            loading:false
          };
      },
      created() {
          if (this.$store.state.clientsAdmin===undefined){
            this.loading = true;
            let that=this;
             this.$store.dispatch('getClientsAdmin').then(function(){
                   that.loading = false;
             }
             );
          }
      },
      mounted(){
      },
      destroyed(){
      },
      computed: {
        clients() {
            if (this.$store.state.clientsAdmin!==undefined){
              console.log("clients updated");
              return this.$store.state.clientsAdmin.content;
            }else{
              return undefined;
            }
        },
        totalItems(){
          if (this.$store.state.clientsAdmin!==undefined){
            return this.$store.state.clientsAdmin.totalElements;
          }else{
            return undefined
          }
        },
        size(){
          if (this.$store.state.clientsAdmin!==undefined){
            return this.$store.state.clientsAdmin.size;
          }else{
            return undefined
          }
        },
      },
      methods:{
        deleteUser(user){
            var r = confirm("Are you sure you want to delete this user ("+user.email+")!");
            if (r == true) {
                console.log("ok");
            } else {
              console.log("cancel");
              return
            }
            this.$set(user,'loading', true);
            this.$store.dispatch('deleteUser',user).then(response => {
                if (response!=undefined){
                    this.$store.dispatch('showSnackbar', {
                        text: Constants.DELETED,
                        color: "primary"
                    })
                    console.log(response);
                    this.$store.dispatch('getClientsAdmin');
                }
            }).finally(()=>{
                this.$set(user,'loading', undefined);
            });
        },
        searchClients(){
          this.loading=true;
          this.$store.dispatch('getClientsAdmin',{pageNumber:0,size:this.options.itemsPerPage,sortBy:this.options.sortBy.join(),sortDesc:this.options.sortDesc.join(),search:this.search}).finally(()=> {
                this.loading = false;
              }
          );
        },
      },
      watch:{
        options(val){
          this.loading=true;
          this.$store.dispatch('getClientsAdmin',{pageNumber:val.page-1,size:val.itemsPerPage,sortBy:val.sortBy.join(),sortDesc:val.sortDesc.join(),search:this.search}).finally(()=> {
                this.loading = false;
              }
          );
        },
      }
    }
</script>

<style scoped>

</style>